//---------selectpicker-----------//
app.directive('selectpicker', function($timeout)
{
  return {
      restrict: 'E',
      scope: {
          array: '=',
          model: '=',
          class: '=',
          selected: '=',
          list: '&'

      },
      transclude: true,
      template: '<select ng-options="o.value as o.option for o in array"><option value=""></option></select>',
      replace: true,
      link: function(scope, element, attrs) {
         $(element).selectpicker();
         $(element).selectpicker('refresh');
         $(element).parent().selectpicker('refresh');
      }
  }
});
//---------multiple selectpicker-----------//

  app.directive('multiselectpicker', function () {
    return {
        restrict: 'E',
        scope: {
            array: '=',
            model: '=',
            class: '='
        },
        template: '<select ng-model="model" multiple data-selected-text-format="count" ng-options="o.id as o.name for o in array"></select>',
        replace: true,
        link: function(scope, element, attrs) {            
            $(element).selectpicker();
        }
    };
});

//---------ng-file directive for file upload-----------//
app.directive('ngFiles', ['$parse', function ($parse) {
    function fn_link(scope, element, attrs) {
        var onChange = $parse(attrs.ngFiles);
        element.on('change', function (event) {
            onChange(scope, { $files: event.target.files });
        });
    };

    return {
        link: fn_link
    }
} ]);


//Directive for tabs popups---/
  app.directive('tabs', function() {

    return {
      restrict: 'E',
      transclude: true,
      scope: {},
      controller: ["$scope", function($scope) {
        var panes = $scope.panes = [];        
 
        $scope.select = function(pane) {
          angular.forEach(panes, function(pane) {
            pane.selected = false;
          });
          pane.selected = true;
        }
 
        this.addPane = function(pane) {
          if (panes.length == 0) $scope.select(pane);
          panes.push(pane);
        }

      }],
      template:
        '<div class="tabs">' +
          '<ul class="nav nav-tabs">' +
            '<li ng-repeat="pane in panes" ng-class="{active:pane.selected, disabled:form.vacancy_order_form.$invalid}">'+
              '<a translate="" ng-click="select(pane)" id="pane{{ $index }}">{{pane.title}}</a>' +
              '<span class="tab-arrow"><i class="fa fa-caret-up" aria-hidden="true"></i></span>' +
            '</li>' +
          '</ul>' +
          '<div class="tab-content" ng-transclude></div>' +
        '</div>',
      replace: true
    };
  }).
  directive('pane', function() {
    return {
      require: '^tabs',
      restrict: 'E',
      transclude: true,
      scope: { title: '@' },
      link: function(scope, element, attrs, tabsCtrl) {
        tabsCtrl.addPane(scope);
      },
      template:
        '<div class="tab-pane" ng-class="{active: selected}" ng-transclude>' +
        '</div>',
      replace: true
    };
})

app.directive('backButton', function(){
    return {
      restrict: 'A',

      link: function(scope, element, attrs) {
        element.bind('click', goBack);

        function goBack() {
          history.back();
          scope.$apply();
        }
      }
    }
});
//---------simple Captcha-----------//
app.directive('simpleCaptcha', function() {
    return {
        restrict: 'E',
        scope: { valid: '=' },
        template: '<input ng-model="a.value" ng-show="a.input" style="width:2em; text-align: center;"><span ng-hide="a.input">{{a.value}}</span>&nbsp;{{operation}}&nbsp;<input ng-model="b.value" ng-show="b.input" style="width:2em; text-align: center;"><span ng-hide="b.input">{{b.value}}</span>&nbsp;=&nbsp;{{result}}',
        controller: function($scope) {
            
            var show = Math.random() > 0.5;
            
            var value = function(max){
                return Math.floor(max * Math.random());
            };
            
            var int = function(str){
                return parseInt(str, 10);
            };
            
            $scope.a = {
                value: show? undefined : 1 + value(4),
                input: show
            };
            $scope.b = {
                value: !show? undefined : 1 + value(4),
                input: !show
            };
            $scope.operation = '+';
            
            $scope.result = 5 + value(5);
            
            var a = $scope.a;
            var b = $scope.b;
            var result = $scope.result;
            
            var checkValidity = function(){
                if (a.value && b.value) {
                    var calc = int(a.value) + int(b.value);
                    $scope.valid = calc == result;
                } else {
                    $scope.valid = false;
                }
                $scope.$apply(); // needed to solve 2 cycle delay problem;
            };
            
            
            $scope.$watch('a.value', function(){    
                checkValidity();
            });
            
            $scope.$watch('b.value', function(){    
                checkValidity();
            });
            
            
            
        }
    };
});

//---------limit to -----------//
app.directive("limitTo", [function() {
    return {
        restrict: "A",
        link: function(scope, elem, attrs) {
          var decimal=  /^\d+\.\d{0,2}$/; 
            var limit = parseInt(attrs.limitTo);
            var maxamt = parseFloat(1000000);
            angular.element(elem).on("keypress", function(e) {                
                var amt = (this.value!='' && this.value!='NaN' && this.value!=null)?parseFloat(this.value):0;
                //console.log(amt);
                if (parseFloat(amt) > parseFloat(maxamt)) {
                  scope.schemeApplication.amount_of_loan=amt.toFixed(); 
                  
                  e.preventDefault(); }
            });
        }
    }
}]);
app.directive("maxAmt", [function() {
    return {
        restrict: "A",
        link: function(scope, elem, attrs) {
            var limit = parseInt(attrs.maxAmt);
            angular.element(elem).on("keypress", function(e) {
                if (this.value == limit) e.preventDefault();
            });
        }
    }
}]);
//----
//---------limit to -----------//
app.directive('numericOnly', function(){
    return {
        require: 'ngModel',
        link: function(scope, element, attrs, modelCtrl) {

            modelCtrl.$parsers.push(function (inputValue) {
                var transformedInput = inputValue ? inputValue.replace(/[^\d.-]/g,'') : null;

                if (transformedInput!=inputValue) {
                    modelCtrl.$setViewValue(transformedInput);
                    modelCtrl.$render();
                }

                return transformedInput;
            });
        }
    };
});

//------------- making input file to dirty on change ---------//
app.directive('ngFileDirty', function(){
    return {
        require : '^form',
        transclude : true,
        link : function($scope, elm, attrs, formCtrl){
            elm.on('change', function(){
                formCtrl.$setDirty();
                $scope.$apply();
            }); 
        }   
    }   
  });

